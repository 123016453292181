<template>
  <div class="tool">
    <el-button class="flat" type="primary" @click="download" v-if="false">
      <i-icon name="icon-download"/>
    </el-button>
    <el-button class="flat" type="primary" @click="print">
      <i-icon name="icon-printer"/>
    </el-button>
    <el-button class="flat" type="primary" @click="full">
      <i-icon v-if="this.fullScreenInner" name="icon-fullscreen-exit"/>
      <i-icon v-else name="icon-fullscreen"/>
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    fullScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return  {
      fullScreenInner: this.fullScreen
    }
  },
  methods: {
    print() {
      window.print()
    },
    full() {
      this.fullScreenInner = !this.fullScreenInner
      this.$emit('update:fullScreen', this.fullScreenInner)
    },
    download() {
      this.$emit('download')
    }
  }
}
</script>


<style lang="scss" scoped>
.tool {
  position: absolute;
  top: 40px;
  right: 0;
  .icon {
    font-size: 18px;
  }
}
</style>

